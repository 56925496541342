/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.  
 */
var owlOptions = {
    loop: true,
    margin: 10,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: false,
    nav: true,
    navContainer: 'sr-only',
    responsive: {
        0: {
            items: 2,
            nav: false
        },
        600: {
            items: 3,
            nav: false
        },
        1000: {
            items: 5,
            nav: true,
            loop: false
        }
    }
};
var carousel = $('.owl-carousel');
$(document).ready(function () {
    carousel.on({
        'initialized.owl.carousel': function () {            
            carousel.find('.item').fadeIn( "slow");
            carousel.find('.loading-placeholder').hide();
        }
    }).owlCarousel(owlOptions);
    $("#header").css({height: carousel.find('.item').height()});
});




//jQuery to collapse the navbar on scroll
$(window).scroll(function () {
    if ($(".navbar").offset().top > 50) {
        $(".navbar-fixed-top").addClass("top-nav-collapse");
    } else {
        $(".navbar-fixed-top").removeClass("top-nav-collapse");
    }
});

function backgroundRequest(type, link, params, callback) {
    $.ajax({
        type: type,
        url: link,
        data: (params !== undefined) ? params : '',
        success: function (data) {
            alert(data);
            callback(true);
        },
        error: function (data) {
            alert(data);
            callback(false);
        }
    });
}

function alert(response) {
    var id = Math.floor((Math.random() * 100000000) + 1);
    var html = "<div id=\"alert-" + id + "\" class=\"alert alert-" + response.status + " \"><button type=\"button\" class=\"close\" data-dismiss=\"alert\">&times;<\/button>";
    if (response.title) {
        html += "<h4>" + response.title + "<\/h4>";
    }
    html += response.msg;
    $("#alert-box").hide().html(html).fadeIn('fast');
}

function isInt(value) {
    return !isNaN(value) &&
            parseInt(Number(value)) == value &&
            !isNaN(parseInt(value, 10));
}

function getThesisParams() {
    return {
        query: $("#thesis_name").val(),
        status: $("#thesis_status").val(),
        end: $("#end_year").val(),
        start: $("#start_year").val(),
    };
}
